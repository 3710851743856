.feed-item-container {
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;
}
.rotate {
  font-size: 30px;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.shadow-0 {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.shadow-1 {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.cursor {
  cursor: pointer;
}
