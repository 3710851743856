/* Font Size Classes */
.x .ql-size-small {
  font-size: smaller;
}

.x .ql-size-large {
  font-size: larger;
}

.x .ql-size-huge {
  font-size: 2em;
}

/* Text Alignment Classes */
.x .ql-align-left {
  text-align: left;
}

.x .ql-align-center {
  text-align: center;
}

.x .ql-align-right {
  text-align: right;
}

.x .ql-align-justify {
  text-align: justify;
}

/* Font Style Classes */
.x .ql-bold {
  font-weight: bold;
}

.x .ql-italic {
  font-style: italic;
}

.x .ql-underline {
  text-decoration: underline;
}

.x .ql-strike {
  text-decoration: line-through;
}

/* Text Color Classes */
.x .ql-color-red {
  color: red;
}

.x .ql-color-blue {
  color: blue;
}

.x .ql-color-green {
  color: green;
}

/* Background Color Classes */
.x .ql-bg-color-yellow {
  background-color: yellow;
}

.x .ql-bg-color-gray {
  background-color: gray;
}

/* List and Indentation Classes */
.x .ql-list {
  list-style-type: disc;
}

.x .ql-indent-1 {
  margin-left: 1em;
}

.x .ql-indent-2 {
  margin-left: 2em;
}

/* Link Classes */
.x a {
  color: #8f12fcd1;
  text-decoration: underline;
}

/* Blockquote Class */
.x .ql-blockquote {
  border-left: 4px solid #333;
  margin: 0;
  padding-left: 1em;
  color: #555;
  font-style: italic;
}

/* Other Styles */
.x p {
  margin: 1em 0;
  font-size: 1em;
}

.x .title {
  font-size: 2.75em !important;
  font-weight: bolder;
  margin: 0.5em 0;
  color: #6750a4 !important;
}

.x h1 {
  font-size: 2.25em;
  font-weight: bolder;
  margin: 0.5em 0;
  color: #333;
}

.x h2 {
  font-size: 1.75em;
  font-weight: bold;
  margin: 0.5em 0;
}

.x .ql-font-serif {
  font-family: serif;
}

.x .ql-font-sans {
  font-family: sans-serif;
}

.x .ql-font-monospace {
  font-family: monospace;
}
