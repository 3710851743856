@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Roboto:wght@300&display=swap");

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  font-family: "Roboto";
  box-sizing: inherit;
}



.slick-dots li.slick-active button:before
{
  font-family: 'slick';
  font-size: 16px !important;
  line-height: 20px;
  color: #003D5F !important;
}

.slick-dots li button:before
{
  font-family: 'slick';
  font-size: 16px !important;
  line-height: 20px;
  color: #D9D9D9 !important;
}

.slick-slide {
  padding: 0 8px;
  box-sizing: border-box;
}

/*.slick-slide {*/
/*  margin-right: 0px;*/
/*}*/

.shadow-primary {
  box-shadow: 0px 4px 10px 0px rgba(28, 27, 31, 0.05);
}

.shadow-secondary {
  box-shadow: -5px 34px 20px -2px rgba(74, 74, 74, 0.05);
}

.shadow-hover {
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.05);
}

.shadow-default {
  box-shadow: 4px 8px 20px -2px rgba(74, 74, 74, 0.03);
}

.grid-cols-1 {
  grid-template-columns: repeat(1, 1fr);
}
.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}
.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}
.video-container {
  display: grid;
  gap: 15px;
  width: 100%;
  height: 80vh;
  background: grey;
  overflow: hidden;
  border-radius: 12px;
}

.agora_video_player {
  object-fit: contain !important;
  border-radius: 12px !important;
}

/* ===== Book Recommendations Scrollbar CSS ===== */
/* Firefox */
.book-recommendations,
.feeds-container {
  scrollbar-width: auto;
  scrollbar-color: #a8a8a8 #ffffff;
}

/* Chrome, Edge, and Safari */
.book-recommendations::-webkit-scrollbar,
.feeds-container::-webkit-scrollbar {
  width: 14px;
}

.book-recommendations::-webkit-scrollbar-track,
.feeds-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

.book-recommendations::-webkit-scrollbar-thumb,
.feeds-container::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 10px;
  border: 4px solid #ffffff;
}
