.modal-main {
    width: 50%;
    max-width: max-content;
    height: auto;
    background: white;
    border-radius: 5px;
    padding: 16px 12px;
    
    overflow-y: auto;
    transform: translate(-50%,-50%);
    
    position:fixed;
    top:50%;
    left:50%;
}
  
.display-block {
    display: block;
}
  
.display-none {
    display: none;
}
.close-img {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 15px;
    right: 15px;
}