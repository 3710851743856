.shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.circle-small {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.section-5-btn {
  background-color: white;
  color: #21005d;
  border-width: 2px;
  border-color: #21005d;
  width: 100%;
}
.section-5-btn:hover {
  background-color: #21005d;
  color: white;
}
.section-1-btn {
  background-color: #21005d;
  color: white;
  border-width: 2px;
  border-color: #21005d;
}
.section-1-btn:hover {
  background-color: white;
  color: #21005d;
}
.section-1-info {
  width: 40vw;
  margin-top: -45vw;
  margin-bottom: 20vw;
  margin-left: 5vw;
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 600px) {
  
}
